import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

const ProductCardDemoStockLabel = ({ pid }) => {
  const [isDemoProduct, setIsDemoProduct] = useState(false)

  const queryResponse = useStaticQuery(QUERY)
  const all_demo_stock = queryResponse.allWcProducts.nodes

  useEffect(() => {
    const acf_data = all_demo_stock.filter((item) => item.wordpress_id == pid)
    if (acf_data.length > 0) {
      setIsDemoProduct(acf_data[0].acf.is_demo_stock)
    }
  }, [all_demo_stock, pid])

  if (isDemoProduct) {
    return (
      <ProductCardDemoProductLabelContainer
        className={"productCardDemoStockLabelClass"}
      >
        <Text>Demo Unit</Text>
      </ProductCardDemoProductLabelContainer>
    )
  } else {
    return ""
  }
}

const ProductCardDemoProductLabelContainer = styled.div`
  display: none;
  position: absolute;
  z-index: 9;
  width: 200px;
  font-size: 12px;
  text-align: center;
  border-radius: 4px;
  padding: 5px;
  background-color: #ed1c24;
  box-shadow: 0 10px 15px -7px rgba(119, 132, 142, 0.19);
  transform: rotate(-45deg);
  left: -65px;
  top: 20px;
`

const Text = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  text-transform: uppercase;
  color: #ffffff;
`

const QUERY = graphql`
  {
    allWcProducts(
      filter: {
        status: { eq: "publish" }
        catalog_visibility: { ne: "hidden" }
        acf: { is_demo_stock: { eq: true } }
      }
    ) {
      nodes {
        wordpress_id
        acf {
          is_demo_stock
        }
      }
    }
  }
`

export default ProductCardDemoStockLabel
